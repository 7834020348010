import { tv } from 'tailwind-variants';

export const linkButtonVariants = tv({
  base: 'rounded-[42px] inline-flex items-center justify-center py-4 px-8 hover:text-neutral-90 hover:bg-primary-40 text-base transition',
  variants: {
    variant: {
      primary: 'bg-primary-40 text-neutral-90 hover:bg-[#3FF49B]',
      secondary: 'border border-primary-40 bg-transparent text-primary-40',
      tertiary: 'hover:bg-transparent hover:text-white hover:underline bg-transparent',
      quaternary: 'bg-white hover:bg-black hover:text-white',
      quinary: 'bg-transparent text-black border border-white hover:bg-white',
      senary: 'border border-black text-black hover:bg-white',
    },
  },
});
