'use client';
import React from 'react';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import { usePathname } from 'next/navigation';

import useBetterMediaQuery from '@/hooks/media-query';
import { scrollToTop } from '@/functions/scrollToTop';

import { Button } from '@/components/ui/button';
import { LinkButton } from '@/components/ui/link-button';
import { SwiperButtonPrev, SwiperButtonNext } from '@/components/ui/swiper-button';
import { TestimonialSectionProps } from '@/components/sections/testimonial/testimonial.interfaces';

import 'swiper/css';
import 'swiper/css/pagination';
import '@/styles/newSwiper.css';
import '@/styles/swiperModalities.css';

export const Testimonials: React.FC<TestimonialSectionProps> = ({ testimonials, buttonText, link, title }) => {
  const locale = usePathname()?.includes('/mx/');

  const { matches: isMD } = useBetterMediaQuery('769px');

  const widthSwiper = isMD ? 400 : 250;

  return (
    <section className="overflow-hidden px-6 py-8 md:py-10">
      <div className="container mx-auto">
        <div className="flex flex-col">
          <div className="flex flex-col gap-4 md:flex-row">
            <div className="rounded-2xl bg-primary-40 px-6 py-4 md:mx-0 md:ml-8 md:h-[250px] md:content-end">
              <h2 className="w-52 text-3xl font-semibold">{title}</h2>
            </div>
            <div>
              <Swiper
                modules={[Pagination, Navigation]}
                pagination={{
                  clickable: true,
                }}
                width={widthSwiper}
                spaceBetween={16}
                data-test="swiper-slide"
              >
                <div>
                  {testimonials &&
                    testimonials.map((item, index) => (
                      <SwiperSlide key={index}>
                        <div className="flex h-[400px] flex-col justify-between rounded-2xl bg-white p-4 md:h-[250px]">
                          <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-2">
                              <Image src={item.url} alt={item.alt} width={40} height={40} />
                              <h3 className="font-semibold uppercase">{item.company}</h3>
                            </div>
                            {locale ? <p className="text-sm">{item.content}</p> : <p>{item.content}</p>}
                          </div>
                          {item.person && (
                            <p className="text-sm" dangerouslySetInnerHTML={{ __html: `${item.person}` }} />
                          )}
                        </div>
                      </SwiperSlide>
                    ))}
                </div>
                <div className="hidden md:flex md:gap-4 md:py-4">
                  <SwiperButtonPrev />
                  <SwiperButtonNext />
                </div>
              </Swiper>
            </div>
          </div>
          {link ? (
            <LinkButton className="w-full self-center md:w-2/4 lg:w-1/4" variant="primary" href={link}>
              {buttonText}
            </LinkButton>
          ) : (
            <Button className="w-full self-center md:w-2/4 lg:w-1/4" onClick={scrollToTop} variant="primary">
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};
