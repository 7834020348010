'use client';
import React from 'react';
import { LinkButtonProps } from './link-button.interfaces';
import { linkButtonVariants } from './link-button.variants';
import Link from 'next/link';
import { cn } from '@/utils';

export const LinkButton: React.FC<LinkButtonProps> = ({ variant, className, href, target, ...props }) => {
  return <Link className={cn(linkButtonVariants({ variant }), className)} href={href} target={target} {...props} />;
};
