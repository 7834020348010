'use client';
import React from 'react';
import Image from 'next/image';
import { usePathname } from 'next/navigation';

import { LinkButton } from '@/components/ui/link-button';

import { howItWorksProps } from './howItWorks.interfaces';

export const HowItWorks: React.FC<howItWorksProps> = ({ props, target }) => {
  const locale = usePathname()?.includes('/mx/');

  return (
    <section className="bg-[#F3F3F6]">
      <div className="container mx-auto">
        <div className="px-8 py-8 lg:py-11">
          <h2 className="w-[250px] text-2xl font-semibold md:w-full md:text-5xl lg:w-1/2 lg:leading-[130%]">
            {props.title}
          </h2>
          <div
            // eslint-disable-next-line prettier/prettier
            className={`mb-4 mt-6 grid grid-cols-1 gap-4 md:mb-6 ${props.gridCols ? props.gridCols : 'md:grid-cols-3'
              // eslint-disable-next-line prettier/prettier
              } md:gap-6`}
          >
            {props.steps.map((item, index) => (
              <div key={index} className="flex flex-col rounded-2xl bg-white p-4">
                <div className="flex flex-col gap-4">
                  <Image
                    src={item.image.icon}
                    alt={item.image.alt}
                    title={item.image.title}
                    width={item.image.width}
                    height={item.image.height}
                  />
                  {item.stepTitle && <h3 className="text-xl font-normal">{item.stepTitle}</h3>}
                  {item.stepDescription && <p className="text-sm">{item.stepDescription}</p>}
                </div>
              </div>
            ))}
          </div>
          <div className={`mx-auto flex ${props.gridCols ? 'max-w-3xl' : 'max-w-md'} flex-col gap-5 md:flex-row`}>
            {props.linkButton && (
              <LinkButton
                className="w-full"
                href={`${props.linkButton}`}
                variant="primary"
                target={target ? target : '_self'}
              >
                {props.buttonText}
              </LinkButton>
            )}
            {props.gridCols && (
              <LinkButton
                href={props.planButton ? props.planButton : '#plans'}
                className="w-full border border-black bg-transparent"
                variant="quaternary"
              >
                {locale ? 'Ver planes' : 'Ver planos'}
              </LinkButton>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
