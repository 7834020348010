import Image from 'next/image';
import { useSwiper } from 'swiper/react';

import ArrowLeft from '@/assets/icons/black-left-arrow.svg';
import ArrowRight from '@/assets/icons/black-right-arrow.svg';

export const SwiperButtonPrev = () => {
  const swiper = useSwiper();
  return (
    <button type="button" onClick={() => swiper.slidePrev()} className="rounded-full bg-white p-2">
      <Image src={ArrowLeft} alt="Ícone de uma seta para a esquerda" />
    </button>
  );
};

export const SwiperButtonNext = () => {
  const swiper = useSwiper();
  return (
    <button type="button" onClick={() => swiper.slideNext()} className="rounded-full bg-white p-2">
      <Image src={ArrowRight} alt="Ícone de uma seta para a direita" />
    </button>
  );
};
