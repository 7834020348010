import { useEffect, useState } from 'react';

const useBetterMediaQuery = (mediaQueryString: string) => {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(`(min-width: ${mediaQueryString})`);

    const listener = () => setMatches(!!mediaQueryList.matches);

    listener();

    mediaQueryList.addEventListener('change', listener);

    return () => mediaQueryList.removeEventListener('change', listener);
  }, [mediaQueryString]);

  return { matches };
};

export default useBetterMediaQuery;
